

// https://capacitorjs.com/docs/apis/device Device ID
import { Plugins } from '@capacitor/core';
import { onMounted } from 'vue';

import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    IonThumbnail,
} from "@ionic/vue";
import { pin, wine, warning, walk, rocket, logoTwitter, logoFacebook, logoInstagram, newspaper, heartOutline, heart, notifications, shareSocial } from "ionicons/icons";

import { defineComponent } from 'vue';
export default defineComponent({
    data: function() {
        return {};
    },
    components: {
        IonButton,
        IonButtons,
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardSubtitle,
        IonCardTitle,
        IonCol,
        IonContent,
        IonGrid,
        IonHeader,
        IonIcon,
        IonItem,
        IonMenuButton,
        IonPage,
        IonRow,
        IonText,
        IonTitle,
        IonToolbar,
        IonThumbnail,
    },
    methods: {},
    setup() {

        const { Device } = Plugins;
        onMounted(async () => {
            const info = await Device.getInfo();
            console.log('Device-Info: ', info);
        });

        return {
            pin,
            wine,
            warning,
            walk,
            rocket,
            logoTwitter,
            logoFacebook,
            logoInstagram,
            newspaper,
            heartOutline,
            heart,
            notifications,
            shareSocial
        };
    },
});
